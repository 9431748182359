.card-container {
  width: 19em;
  height: 28em;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  //   border: 1px solid black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 1em;
  // margin-bottom: 1em;
  overflow: hidden;
}
.card-photo-wrapper {
  width: 100%;
}

.card-info-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 2em;
  h2 {
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: normal;
    margin: 0;
    // margin-bottom: .5em;
  }
  h3 {
    margin: 0;
    margin-bottom: 2.5em;
    font-size: 1em;
    font-weight: lighter;
    text-transform: capitalize;
    text-align: center;
  }
  h4 {
    text-align: center;
    margin: 0;
    margin-bottom: .5em;
    span {
      text-decoration: line-through;
      color: red;
    }
  }
}
