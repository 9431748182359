@import "../../miscellaneous/libs/flexproperties";


.grains-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
//   @include flex-center();
}

.grains-navigation-wrapper{
    width: 100%;
    margin-top: 4.5em; // navigationBar.js is set to a fixed position
      @include flex-center();
}

.grains-card-container{
  width: 100%;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1em;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}