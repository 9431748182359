

.navigation-bar-container{
    width: 100%;
    height: 4em;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigation-bar-arrow-wrapper{
    margin-left: .5em;
}
.navigation-bar-logo-wrapper{
    margin-right: .5em;
    img{
        height: 3em;
        
    }
}