// modal background
@mixin background-web-layout($heightValue, $backColor) {
    position: fixed;
    background-color: $backColor;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: $heightValue;
    z-index: 1700;
    opacity: 0.7;
  }
  // modal elements 
  @mixin modal-web-layout($heightValue, $backColor) {
    position: fixed;
    background: $backColor;
    z-index: 1700;
    width: 100%;
    height: $heightValue;
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow: hidden;
  }

  @mixin modal-position() {
      top: 0;
      left: 0;
  }