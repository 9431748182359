@import "../../miscellaneous/libs/flexproperties";

.beverages-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  position: fixed;
}

.beverages-navigation-wrapper {
  width: 100%;
  margin-top: 4.5em; // navigationBar.js is set to a fixed position
  @include flex-center();
}

.beverages-navigation-wrapper {
  width: 100%;
  margin-top: 4.5em; // navigationBar.js is set to a fixed position
  @include flex-center();
}

.beverages-card-container{
  width: 100%;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1em;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}