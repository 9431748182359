//////////////////////////////////////////////
// 1em = 16px Ex: 576 / 16
// 1em mesurement works better for browsers
// ipad mini 820px
// these variables are used for the media querie manager
$xs: 0;
$sm: 35em; // 576px
$md: 51.25em; // 820px
$lg: 62em; //992px;
$xl: 75em; // 1200px;
$xxl: 1400px;
//////////////////////////////////////////////

// font-size: 1rem = 16px -> if you want 8px, then 8/16*100 result will be in percentage
// @mixin response-phone {
//     @media (max-width: $sm){ @content };
// }

// @mixin reusability for media use
// @content allow to input an entire code block
// @breakpoint is the parameter being passed
// from larger to small order to as expexted
//////////////////////////////////////////////
/// MEDIA QUIERIE MANAGER
@mixin response-media($breakpoint) {
  @if ($breakpoint == xlarge) {
    @media (max-width: $xl) {
      @content;
    } // 820px
  } @else if ($breakpoint == large) {
    @media (max-width: $lg) {
      @content;
    } // 820px
  } @else if($breakpoint == medium) {
    @media (max-width: $md) {
      @content;
    } // 820px
  } @else if($breakpoint == small) {
    @media (max-width: $sm) {
      @content;
    } // 576px
  } @else if($breakpoint == height) {
    @media (max-height: $sm) {
      @content;
    }
  }
}