.icon-whatapp {
    color: rgb(73, 201, 73);
    width: auto;
    font-size: 1.8em;
    margin-right: 0.6em;
  }

  .icon-button-container {
    background: transparent;
    margin: 0;
    padding: 0;
    height: auto;
    width: auto;
    cursor: pointer; //implemented in all icons 
    border: none;
  }
  .icon-back{
    width: 1.4em;
    height: 1em;
    font-size: 2.4em;
    color: white;
    
  }
  .icon-phone{
    font-size: 1.4em;
    color: rgb(255, 255, 255);
  }
  .icon-exclamation{
    color: white;
    padding-right: 1em;

  }
  .icon-menu{
    width: 1.3em;
    height: 1.1em;
    font-size: 2.5em;
    color: white;
    margin-right: .5em;
    transform: rotate(-90deg);
    overflow: hidden;
    
  }
  .icon-noti{
    font-size: 2em;
    color: white;
  }