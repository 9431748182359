@import "../miscellaneous/libs/animation";
@import "../miscellaneous/libs/modal";


/////////////////////////////////////////////
/// animation from top to bottom
.modal-background {
  // first parameter indicates the height value
  @include background-web-layout(auto, black);

  // it creates the animation starting point to
  // the ending point. Backframes is the name
  @include keyframes-response(opacityani);

  // opacity animated
  @include animated("opacityani .3s  ease-in-out");
}
.modal-style {
  // first parameter indicates the height value
  @include modal-web-layout(auto, transparent);
  @include keyframes-response(toptobottom);
  @include animated("toptobottom .6s ease-in-out");

  @include modal-position();
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em;
}
