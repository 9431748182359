@import "../libs/buttons";

.button-link-wrapper {
  @include button-link(rgb(132, 128, 128));
  border: none;
  text-align: left;
  margin-left: 0.5;
}

.button-border-bottom {
  @include button-border(rgb(132, 128, 128));
  color: white;
}
.button-wrapper {
  @include button-link(rgb(132, 128, 128));
  width: 18em;
  height: 2.5em;
  font-size: 0.8em;
}
.button-wrapper-app {
  @include button-link(rgb(132, 128, 128));
  width: 13em;
  font-size: 0.8em;
  height: 2.5em;
  margin: 0;
}
.button-application-wrapper {
  @include button-link(rgb(102, 102, 102));
  width: 22em;
  height: 2.5em;
}
