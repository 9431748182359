@mixin button-link($color) {
  width: 8.5em;
  height: 1.2em;
  background-color: transparent;
  border: 2px solid $color;
  color: $color;
  text-decoration: none;
  text-align: center;
  padding: 0.4em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  cursor: pointer;
}

@mixin button-border($color) {
  width: 15em;
  height: 2.2em;
  background-color: transparent;
  color: $color;
  text-align: center;
  padding: 0.5em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  border: none;
  border-bottom: 2px solid $color;
  cursor: pointer;
}
