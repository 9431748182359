@import "../../miscellaneous/libs/media";
@import "../../miscellaneous/libs/flexproperties";

//////////////////////////////
/// MainPage.js
.main-page-container {
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: fixed;

  nav {
    width: 100%;
    height: 4em;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: flex-end;
    // @include response-media(medium) {
    //   width: 100%;
    // }
  }

  address {
    color: white;
    text-align: center;
    font-style: normal;
  }
}
.main-page-subnav {
  width: 50vh;
  height: 100vh;
  margin-top: 4em; 
  background: white;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  h4{
    width: 100%;
    text-align: center;
    font-size: 1.5em;
  }
   @include response-media(small) {
      width: 60%;
    }
 
}

.main-page-logo {
  width: 100%;
  color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  img {
    width: 10em;
    height: 8em;
  }
  h4 {
    letter-spacing: 0.15em;
  }
}
.main-page-small-divider {
  width: 7em;
  border-bottom: 1px solid white;
}

.main-page-phone {
  width: auto;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
  }

  h5 {
    margin: 0;
    margin-right: 0.5em;
  }
}
.main-page-raffle {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 3em;
}

.main-page-disclaimer {
  width: 100%;
  color: white;
  display: flex;
  font-size: 0.6em;
  margin-bottom: 1.7em;
  display: flex;
  justify-content: space-between;
  span {
    padding-left: 0.5em;
  }
}

////////////////////////////////
/// Modal
.main-page-modal-container {
  width: 100%;
  height: 100%;
 
}

////////////////////////////////
/// Offers.js
.main-page-offers {
  width: 100%;
  height: 6em;
  margin-top: 4em;
  background: white;
  @include flex-center();
}
