.search-engine-main {
    width: 20em;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    align-items: center;
  }
  
  .search-engine-container {
    width: 20em;
    height: 4.7em;
    border-radius: 0.5em;
    text-indent: 0.3em;
    font-size: 0.9em;
    color: black;
    text-transform: uppercase;
    border: 1px solid black;
    font-family: "Josefin Sans", sans-serif;
    background: transparent;
    box-shadow: 1px 1px 3px rgb(144, 142, 142);
    
    
  }
  
  .search-engine-wrapper {
    width: 95%;
    height: 2em;
    
    display: flex;
    justify-content: center;
    // border: 2px solid red;
  }
  
  .search-engine-deco {
    width: 1em;
    height: 3em;
    border-left: outset  rgb(180, 180, 180);
  }

  //   border: 2px solid red;