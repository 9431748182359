//////////////////////////////////////////////
/// Animations
//  it creates a sequence of styles to be applied to an
// element over a set of animation points.
@mixin keyframes-response($element) {
    @if ($element == toptobottom) {
      // navekeyframes is the assigned to keyframes
      @keyframes #{toptobottom} {
        from {
          transform: translateY(-200%);
        }
        to {
          transform: translateY(0);
        }
      }
    } @else if ($element == leftani) {
      // navekeyframes is the assigned to keyframes
      @keyframes #{leftani} {
        from {
          transform: translateX(-200%);
        }
        to {
          transform: translateX(0);
        }
      }
    } @else if ($element == rightani) {
      // navekeyframes is the assigned to keyframes
      @keyframes #{rightani} {
        from {
          transform: translateX(200%);
        }
        to {
          transform: translateX(0);
        }
      }
    } @else if($element == opacityani) {
      @keyframes #{opacityani} {
        0% {
          opacity: 0;
        }
        20% {
          opacity: 0;
        }
        40% {
          opacity: 0.2;
        }
        50% {
          opacity: 0.4;
        }
        60% {
          opacity: 0.7;
        }
        70% {
          opacity: 0.8;
        }
        100% {
          opacity: 0.9;
        }
      }
    }
  }
  
  // the element will smoothly transition through the specified
  // keyframe points in keyframes-response(){@content}, applying
  // the styles defined at each point, creating the animation effect.
  @mixin animated($animation_properties) {
    -webkit-animation: #{$animation_properties};
    -moz-animation: #{$animation_properties};
    -ms-animation: #{$animation_properties};
    -o-animation: #{$animation_properties};
    animation: #{$animation_properties};
  }