@import "../../miscellaneous/libs/flexproperties";

.application-container {
  width: 100%;
  height: 100%;
}
.application-wrapper {
  width: 100%;
  height: 20vh;
  margin-top: 6em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: wrap;
}
///////////////////////////
/// buttons, for notifications and installation
.application-buttons-wrapper {
  width: 20em;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.application-icon-notif {
  font-size: 1em;
  color: white;
  cursor: pointer;
}
///////////////////////////
/// Modal
.application-modal-container {
  width: 100%;
  height: 100%;
  background-color: white;
  @include flex-center();
}
.application-modal-text {
  width: 90%;

  @include flex-center();
  margin-top: 4em;
  h4 {
    margin: 0;
    text-align: center;
    font-weight: lighter;
    font-size: 1.1em;
    line-height: 1.6em;
    margin-bottom: 1em;
  }
  img {
    height: 10vh;
    width: auto;
  }
}
.application-modal-images {
  width: 90%;
  @include flex-center();
  margin-bottom: 1em;

  img {
    height: 30vh;
    width: auto;
  }
}
