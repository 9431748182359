swiper-container {
  width: 100%;
  height: 100%;
  --swiper-navigation-color: #823838;
  background-color: white;
}

swiper-slide {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 14px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

}

.swiper-image-control {
  width: 15em;
  height: 20em;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
  img {
    width: auto;
    height: 100%;
  }
  &:hover{
    transform: scale(1.6,1.6);
  }
}

swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
